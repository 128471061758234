/////////////////////////////////////////////////////
/// ////// COMMON STYLE
/// /////////////////////////////////////////////////
.text-heading-xxxl,
.text-heading-xxl,
.text-heading-xl,
.text-heading-lg,
.text-heading-md,
.text-heading-sm,
.text-body-lg,
.text-body-lg-medium,
.text-body-md,
.text-body-xxsm,
.text-body-xsm,
.text-body-sm,
.text-heading-xxxl-semibold,
.text-heading-xxl-semibold,
.text-heading-xl-semibold,
.text-heading-lg-semibold,
.text-heading-md-semibold,
.text-heading-sm-semibold,
.text-body-lg-semibold,
.text-body-md-semibold,
.text-body-xxsm-semibold,
.text-body-xsm-semibold,
.text-body-sm-semibold,
.text-heading-xxxl-bold,
.text-heading-xxl-bold,
.text-heading-xl-bold,
.text-heading-lg-bold,
.text-heading-md-bold,
.text-heading-sm-bold,
.text-body-lg-bold,
.text-body-md-bold,
.text-body-xxsm-bold,
.text-body-xsm-bold,
.text-body-sm-bold {
  padding: 0;
  color: var(--neutral-100);

  &.primary {
    color: var(--purple-500);
  }

  &.secondary {
    color: var(--neutral-300);
  }

  &.disabled {
    color: var(--neutral-400);
  }

  &.black {
    color: var(--neutral-700);
  }

  &.success {
    color: var(--green-500);
  }

  &.danger {
    color: var(--red-500);
  }

  &.alert {
    color: var(--yellow-500);
  }

  &.info {
    color: var(--blue-500);
  }
}

/////////////////////////////////////////////////////
/// ////// REGULAR
/// /////////////////////////////////////////////////

.text-heading-xxxl {
  font-weight: var(--font-regular);
  font-size: 64px;
  line-height: 72px;
}
.text-heading-xxl {
  font-weight: var(--font-regular);
  font-size: 48px;
  line-height: 56px;
}
.text-heading-xl {
  font-weight: var(--font-regular);
  font-size: 40px;
  line-height: 48px;
}
.text-heading-lg {
  font-weight: var(--font-regular);
  font-size: 32px;
  line-height: 40px;
}
.text-heading-md {
  font-weight: var(--font-regular);
  font-size: 24px;
  line-height: 32px;
}
.text-heading-sm {
  font-weight: var(--font-regular);
  font-size: 20px;
  line-height: 28px;
}
.text-body-lg {
  font-weight: var(--font-regular);
  font-size: 18px;
  line-height: 26px;
}
.text-body-md {
  font-weight: var(--font-regular);
  font-size: 16px;
  line-height: 24px;
}
.text-body-xxsm {
  font-weight: var(--font-regular);
  font-size: 14px;
  line-height: 20px;
}
.text-body-xsm {
  font-weight: var(--font-regular);
  font-size: 12px;
  line-height: 16px;
}
.text-body-sm {
  font-weight: var(--font-regular);
  font-size: 10px;
  line-height: 14px;
}

.text-body-lg-medium {
  font-weight: var(--font--lg-medium);
  font-size: 18px;
  line-height: 26px;
}

/////////////////////////////////////////////////////
/// ////// SEMI BOLD
/// /////////////////////////////////////////////////

.text-heading-xxxl-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 64px;
  line-height: 72px;
}
.text-heading-xxl-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 48px;
  line-height: 56px;
}
.text-heading-xl-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 40px;
  line-height: 48px;
}
.text-heading-lg-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 32px;
  line-height: 40px;
}
.text-heading-md-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 24px;
  line-height: 32px;
}
.text-heading-sm-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 20px;
  line-height: 28px;
}
.text-body-lg-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 18px;
  line-height: 26px;
}
.text-body-md-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 16px;
  line-height: 24px;
}
.text-body-xxsm-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 14px;
  line-height: 20px;
}
.text-body-xsm-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 12px;
  line-height: 16px;
}
.text-body-sm-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 10px;
  line-height: 14px;
}

/////////////////////////////////////////////////////
/// ////// BOLD
/// /////////////////////////////////////////////////
.text-heading-xxxl-bold {
  font-weight: var(--font-bold);
  font-size: 64px;
  line-height: 72px;
}
.text-heading-xxl-bold {
  font-weight: var(--font-bold);
  font-size: 48px;
  line-height: 56px;
}
.text-heading-xl-bold {
  font-weight: var(--font-bold);
  font-size: 40px;
  line-height: 48px;
}
.text-heading-lg-bold {
  font-weight: var(--font-bold);
  font-size: 32px;
  line-height: 40px;
}
.text-heading-md-bold {
  font-weight: var(--font-bold);
  font-size: 24px;
  line-height: 32px;
}
.text-heading-sm-bold {
  font-weight: var(--font-bold);
  font-size: 20px;
  line-height: 28px;
}
.text-body-lg-bold {
  font-weight: var(--font-bold);
  font-size: 18px;
  line-height: 26px;
}
.text-body-md-bold {
  font-weight: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
}
.text-body-xxsm-bold {
  font-weight: var(--font-bold);
  font-size: 14px;
  line-height: 20px;
}
.text-body-xsm-bold {
  font-weight: var(--font-bold);
  font-size: 12px;
  line-height: 16px;
}
.text-body-sm-bold {
  font-weight: var(--font-bold);
  font-size: 10px;
  line-height: 14px;
}
