// TODO: talvez eu não use
@use '../../../node_modules/bootstrap/dist/css/bootstrap-reboot.css';
@use '../../../node_modules/bootstrap/dist/css/bootstrap-grid.css';
@use '../../../libs/mzic-assets/src/lib/fonts/fonts.scss';

@use '../../../libs/mzic-style/src/lib/style/styles/variables';
@use '../../../libs/mzic-style/src/lib/style/styles/components/general/labels';

body {
  background: variables.$neutral-700;
  font-family: 'PP Mori', Arial, sans-serif;
}
